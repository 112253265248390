<template>
  <TypeOfHeatPumpFormField :choices="choices" v-on="$listeners" />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import TypeOfHeatPumpFormField from 'chimera/all/components/form/fields/typeOfHeatPump/TypeOfHeatPumpFormField.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TypeOfHeatPumpFormPart',

  components: {
    TypeOfHeatPumpFormField,
  },

  /**
   * @returns {{defaultChoices: Selectable[], alternativeHybridChoices: Selectable[]}}
   */
  data() {
    return {
      defaultChoices: this.initializeChoices(),
      alternativeHybridChoices: this.initializeAlternativeChoices(),
    }
  },

  computed: {
    ...mapGetters({
      getData: 'lead/getData',
    }),

    /**
     * @returns {Selectable[]}
     */
    choices() {
      const typeOfExistingRadiatorStore = this.$store.getters['lead/getData'](
        'type-of-existing-radiator',
      )
      const intendsToReplaceRadiatorStore = this.$store.getters['lead/getData'](
        'intends-to-replace-radiator',
      )

      const typeOfExistingRadiator = Array.isArray(typeOfExistingRadiatorStore)
        ? typeOfExistingRadiatorStore[0].value
        : null
      const intendsToReplaceRadiator = Array.isArray(
        intendsToReplaceRadiatorStore,
      )
        ? intendsToReplaceRadiatorStore[0].value
        : null

      const hybridChoicesOnly =
        typeOfExistingRadiator ===
          this.$t('field.type-of-existing-radiator.options.regular-radiator') &&
        intendsToReplaceRadiator === this.$t('global.no')

      return hybridChoicesOnly
        ? this.alternativeHybridChoices
        : this.defaultChoices
    },
  },

  /**
   * @returns {Promise<void>}
   */
  async beforeMount() {
    if (this.choices === this.alternativeHybridChoices) {
      await this.$store.dispatch('lead/add', {
        key: 'type-of-heat-pump',
        value: this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
      })
    }
  },

  methods: {
    /**
     * @returns {Selectable[]}
     */
    initializeChoices() {
      // Initialization code for defaultChoices
      return [
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.air-air-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-air-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-air-heat-pump'),
          {
            groupHelp: this.$i18n.t('groupHelp.air-air-heat-pump', {
              option: this.$i18n.t(
                'field.type-of-heat-pump.options.air-air-heat-pump',
              ),
            }),
          },
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.air-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.air-water-heat-pump'),
          {
            groupHelp: this.$i18n.t('groupHelp.air-water-heat-pump', {
              option: this.$i18n.t(
                'field.type-of-heat-pump.options.air-water-heat-pump',
              ),
            }),
          },
        ),
        new Selectable(
          this.$i18n.t(
            'field.type-of-heat-pump.options.ground-water-heat-pump',
          ),
          this.$i18n.t(
            'field.type-of-heat-pump.options.ground-water-heat-pump',
          ),
          this.$i18n.t(
            'field.type-of-heat-pump.options.ground-water-heat-pump',
          ),
          {
            groupHelp: this.$i18n.t('groupHelp.ground-water-heat-pump', {
              option: this.$i18n.t(
                'field.type-of-heat-pump.options.ground-water-heat-pump',
              ),
            }),
          },
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.water-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.water-water-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.water-water-heat-pump'),
          {
            groupHelp: this.$i18n.t('groupHelp.water-water-heat-pump', {
              option: this.$i18n.t(
                'field.type-of-heat-pump.options.water-water-heat-pump',
              ),
            }),
          },
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          {
            groupHelp: this.$i18n.t('groupHelp.hybrid-heat-pump', {
              option: this.$i18n.t(
                'field.type-of-heat-pump.options.hybrid-heat-pump',
              ),
            }),
          },
        ),
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.unknown'),
          this.$i18n.t('field.type-of-heat-pump.options.unknown'),
          this.$i18n.t('field.type-of-heat-pump.options.unknown'),
        ),
      ]
    },

    /**
     * @returns {Selectable[]}
     */
    initializeAlternativeChoices() {
      // Initialization code for alternativeHybridChoices
      return [
        new Selectable(
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          this.$i18n.t('field.type-of-heat-pump.options.hybrid-heat-pump'),
          {
            groupHelp: this.$i18n.t('groupHelp.hybrid-heat-pump-alternative'),
          },
        ),
      ]
    },
  },
}
</script>

<i18n>
{
  "nl-NL":  {
    "groupHelp": {
      "air-air-heat-pump": "<strong>{option}</strong> haalt warmte uit de buitenlucht en circuleert deze binnen voor verwarming. Veel gebruikt in ductless mini-split systemen.",
      "air-water-heat-pump": "<strong>{option}</strong> verplaatst luchtwarmte naar waterverwarming voor ruimteverwarming of warm water en is geschikt voor radiatoren en vloerverwarming.",
      "ground-water-heat-pump": "<strong>{option}</strong> onttrekt warmte uit de grond en verplaatst deze naar water voor verwarmingsdoeleinden. Efficiënt en duurzaam, met gebruik van stabiele grondtemperaturen.",
      "water-water-heat-pump": "<strong>{option}</strong> gebruikt grondwater als warmtebron voor verwarmingssystemen. Het circuleert water via warmtewisselaars om de warmte-energie over te dragen.",
      "hybrid-heat-pump": "<strong>{option}</strong> combineert traditionele verwarming (bv. gas of olie) met een warmtepomp voor efficiënte verwarming en koeling, met back-upverwarming indien nodig.",
      "hybrid-heat-pump-alternative": "Wanneer je standaard radiatoren wil behouden, dan is een hybride warmtepomp de enige & beste optie voor jouw situatie. Een hybride warmtepomp werkt in combinatie met een CV-ketel op aardgas."
    }
  },
  "fr-BE":  {
    "groupHelp": {
      "air-air-heat-pump": "<strong>{option}</strong> extrait la chaleur de l'air extérieur et la fait circuler à l'intérieur pour le chauffage. Souvent utilisé dans les systèmes sans conduit (mini-split).",
      "air-water-heat-pump": "<strong>{option}</strong> transfère la chaleur de l'air vers le chauffage de l'eau pour le chauffage des espaces ou de l'eau chaude et est adapté aux radiateurs et au chauffage par le sol.",
      "ground-water-heat-pump": "<strong>{option}</strong> extrait la chaleur du sol et la transfère à l'eau pour des fins de chauffage. Efficace et durable, utilisant la température stable du sol.",
      "water-water-heat-pump": "<strong>{option}</strong> utilise l'eau souterraine comme source de chaleur pour les systèmes de chauffage. Il fait circuler l'eau via des échangeurs de chaleur pour transférer l'énergie thermique.",
      "hybrid-heat-pump": "<strong>{option}</strong> combine le chauffage traditionnel (par ex. gaz ou fioul) avec une pompe à chaleur pour un chauffage et une climatisation efficaces, avec chauffage d'appoint si nécessaire.",
      "hybrid-heat-pump-alternative": "Si vous souhaitez conserver vos radiateurs standards, une pompe à chaleur hybride est la seule et la meilleure option pour votre situation. Une pompe à chaleur hybride fonctionne en combinaison avec une chaudière à gaz naturel."
    }
  },
  "es-ES":  {
    "groupHelp": {
      "air-air-heat-pump": "<strong>{option}</strong> extrae el calor del aire exterior y lo hace circular hacia el interior para calentarlo. Se utiliza habitualmente en sistemas mini-split sin conductos.",
      "air-water-heat-pump": "<strong>{option}</strong> transfiere el calor del aire al calentamiento de agua para la calefacción de espacios o para obtener agua caliente y es adecuada para radiadores y calefacción por suelo radiante.",
      "ground-water-heat-pump": "<strong>{option}</strong> extrae calor del suelo y lo transfiere al agua para calentarla. Es eficiente y sostenible, utilizando temperaturas estables del suelo.",
      "water-water-heat-pump": "<strong>{option}</strong> utiliza agua subterránea como fuente de calor para los sistemas de calefacción. Hace circular agua a través de intercambiadores de calor para transferir la energía térmica.",
      "hybrid-heat-pump": "<strong>{option}</strong> combina calefacción tradicional (por ejemplo, gas o petróleo) con una bomba de calor, para obtener una calefacción y refrigeración eficientes y con calefacción de reserva si es necesario.",
      "hybrid-heat-pump-alternative": "Si deseas mantener los radiadores estándar, una bomba de calor híbrida sería la única y mejor opción para tu proyecto. Una bomba de calor híbrida funciona en combinación con una caldera de calefacción central de gas natural."
    }
  }
}
</i18n>
